import React, { useEffect, useState, useContext } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { getApi, postApi } from "../../tools/axiosInstances";
import { ReactComponent as WarningLogo } from "../icons/warning.svg";
import { ReactComponent as OkLogo } from "../icons/ok.svg";
import { ReactComponent as SwitchIcon } from "../icons/switch.svg";
import { ReactComponent as CheckCircle } from "../icons/checkCircle.svg";
import { PortalContext } from "../../PortalContext";
import ActionButton from "../layout/buttons/ActionButton";
import MultilineTranslation from "../layout/MultilineTranslation";
import ListItem from "../layout/design/ListItem";
import LoadingText from "../layout/loading/LoadingText";

function AppleAuthorizationInfo({}) {
  const { t } = useTranslation("global");
  const [info, setInfo] = useState({ general: "loading" });
  const { ipadOnboarded, macosOnboarded, setMacosOnboarded, setIpadOnboarded } =
    useContext(PortalContext);
  useEffect(() => {
    getApi("/settings/ipad/mdmstatus/", {}).then((data) => {
      setInfo(data.data);
    });
  }, []);
  const handleActivate = () => {
    const userInfo = JSON.parse(sessionStorage.getItem("me"));
    postApi("/settings/apple/requestonboard/", { userInfo: userInfo }).then(
      () => {
        setIpadOnboarded("requested");
        setMacosOnboarded("requested");
      }
    );
  };
  const appleStatus = () => {
    if (
      info.general !== "loading" &&
      ipadOnboarded !== null &&
      macosOnboarded !== null
    ) {
      if (ipadOnboarded === true && macosOnboarded === true) {
        return (
          <div className="justify-start items-center  flex">
            <div className="text-fstatus-200">{t("words.activated")}</div>
            <OkLogo className="h-6 w-6 text-fstatus-200 mx-2" />
          </div>
        );
      } else if (
        ipadOnboarded === "requested" ||
        macosOnboarded === "requested"
      ) {
        return (
          <div className="justify-start items-center  flex">
            <div className="">{t("words.requested")}</div>
            <OkLogo className="h-6 w-6  mx-2" />
          </div>
        );
      } else {
        return (
          <div className="justify-start items-center  flex">
            <div className="text-fstatus-400">{t("words.deactivated")}</div>
            <WarningLogo className="text-fstatus-400 h-6 w-6 mx-2" />
          </div>
        );
      }
    } else {
      return <LoadingText />;
    }
  };
  return (
    <>
      <div className="m-50 max-w-[850px] py-2">
        <div className="justify-center mb-8">
          <div className="text-xl font-bold pb-4">
            {t("pages.settings.windows.title")}
          </div>
          <div className="text-base text-fgray-400">
            {t("pages.settings.windows.description")}
          </div>
          <ListItem label={t("pages.device_detail.status")}>
            <div className="justify-start items-center  flex">
              <div className="text-fstatus-200">{t("words.activated")}</div>
              <OkLogo className="h-6 w-6 text-fstatus-200 mx-2" />
            </div>
          </ListItem>
        </div>
        <div className="justify-center mb-8">
          <div className="text-xl font-bold pb-4">
            {t("pages.settings.ipads.title")}
          </div>
          <div className="text-base text-fgray-400">
            {t("pages.settings.ipads.description")}
          </div>
        </div>
        <ListItem label={t("pages.device_detail.status")}>
          {appleStatus()}
        </ListItem>
        {info.general === true &&
          ipadOnboarded !== null &&
          macosOnboarded !== null && (
            <ListItem label={t("words.expiration_date")}>{`${moment(
              info.expiration
            ).format("L")}, ${moment(info.expiration).format(
              "H:mm"
            )}`}</ListItem>
          )}
        {info.general !== "loading" &&
          ipadOnboarded !== null &&
          macosOnboarded !== null &&
          (ipadOnboarded === "requested" || macosOnboarded === "requested" ? (
            <>
              {" "}
              <h3 className="text-lg text-fblack font-medium my-2 flex space-x-2">
                <span>{t(`pages.onboard.macos.title_requested`)}</span>
              </h3>
              <div className="text-fgray-400">
                <MultilineTranslation
                  text={t("pages.onboard.macos.body_requested")}
                />
              </div>
            </>
          ) : ipadOnboarded === false || macosOnboarded === false ? (
            <>
              <div className="text-fgray-400 my-4">
                <MultilineTranslation
                  text={t(`pages.onboard.apple.device.body`)}
                />
              </div>
              <ActionButton
                id="activate"
                onClick={handleActivate}
                Icon={SwitchIcon}
                tooltip="tooltip.settings.activate_apple"
                secondary
              >
                {t("words.activate")}
              </ActionButton>
            </>
          ) : (
            <></>
          ))}
      </div>
    </>
  );
}

export default AppleAuthorizationInfo;

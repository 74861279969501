import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Spinner from "../layout/loading/Spinner";
function PendingTasks({ tasks }) {
  const { t } = useTranslation("global");
  const PendingTaskCard = ({ task }) => {
    return (
      <>
        <div className="mx-auto max-w-6xl p-2 overflow-hidden pt-4">
          <div className="flex">
            <Spinner />
            <div className="text-xl font-medium mx-2">
              {t(`pages.device_detail.pending_tasks.${task.actionName}.title`)}
            </div>
          </div>
          <div className="text-fgray-300 truncate my-4">
            {t(
              `pages.device_detail.pending_tasks.${task.actionName}.description`
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {tasks.length > 0 && (
        <div className="m-auto  items-center p-2 overflow-hidden ">
          <div className="max-w-6xl mx-auto p-4 my-2 rounded-xl shadow-lg divide-y">
            {[...new Map(tasks.map((s) => [s.actionName, s])).values()].map(
              (task, index) => {
                return <PendingTaskCard task={task} key={index} />;
              }
            )}
          </div>
        </div>
      )}
    </>
  );
}
export default PendingTasks;

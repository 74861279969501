import { useCallback, useContext, useEffect, useState } from "react";
import { getApi, postApi } from "../tools/axiosInstances";
import { PortalContext } from "../PortalContext";
import { PolicyContext } from "../components/policy/policyContext";
import { uploadFile } from "../tools/axiosInstances";
export const usePolicy = () => {
  const {
    windowsPolicyManagement,
    macosPolicyManagement,
    ipadPolicyManagement,
    deviceMode,
  } = useContext(PortalContext);
  const {
    refresh,
    windowsProfilesStatus,
    ipadProfilesStatus,
    macosProfilesStatus,
    setWindowsObjects,
    setIpadObjects,
    setMacosObjects,
    setWindowsProfilesStatus,
    setMacosProfilesStatus,
    setIpadProfilesStatus,
    filter,
  } = useContext(PolicyContext);
  const handleFetchProfilesStatus = useCallback(
    async (controller) => {
      // const handleFetchProfilesStatus = useCallback(async () => {
      if (!filter) return;
      setWindowsProfilesStatus([]);
      setIpadProfilesStatus([]);
      setMacosProfilesStatus([]);
      try {
        if (windowsPolicyManagement) {
          getApi(
            `/policy/status/${filter}/windows/`,
            {},
            controller.signal
          ).then((response) => setWindowsProfilesStatus(response.data));
        }

        if (ipadPolicyManagement) {
          getApi(`/policy/status/${filter}/ipad/`, {}, controller.signal).then(
            (response) => setIpadProfilesStatus(response.data)
          );
        }

        if (macosPolicyManagement) {
          getApi(`/policy/status/${filter}/macos/`, {}, controller.signal).then(
            (response) => setMacosProfilesStatus(response.data)
          );
        }
      } catch (error) {
        if (error.name !== "AbortError") {
          console.error("Error fetching profiles status:", error);
        }
      }

      return () => {
        controller.abort();
      };
    },
    [
      filter,
      ipadPolicyManagement,
      macosPolicyManagement,
      setIpadProfilesStatus,
      setMacosProfilesStatus,
      setWindowsProfilesStatus,
      windowsPolicyManagement,
    ]
  );
  const handleFetchProfiles = useCallback(
    async (controller) => {
      try {
        const response = await getApi(`/policy/`, {}, controller.signal);
        setWindowsObjects(response.data?.windows || []);
        setIpadObjects(response.data?.ipad || []);
        setMacosObjects(response.data?.macos || []);
      } catch (error) {
        if (error.name !== "AbortError") {
          console.error("Error fetching profiles:", error);
        }
      }

      return () => {
        controller.abort();
      };
    },
    [setIpadObjects, setMacosObjects, setWindowsObjects]
  );
  useEffect(() => {
    const controller = new AbortController(); // Create controller inside `useEffect`
    handleFetchProfilesStatus(controller); // Pass the controller to the function
    return () => controller.abort();
  }, [handleFetchProfilesStatus, filter, refresh]);

  // Effect to fetch general profiles (on mount)
  useEffect(() => {
    const controller = new AbortController(); // Create controller inside `useEffect`
    handleFetchProfiles(controller); // Pass the controller to the function
    return () => controller.abort();
  }, [handleFetchProfiles]);

  const uploadFileParameter = useCallback(
    async (parameter) => {
      try {
        if (!window[parameter.id]) {
          return false;
        }
        const formData = new FormData();
        formData.append("file_data", window[parameter.id]);
        formData.append("file_name", `${filter}_${parameter.id}`);
        formData.append("file_path", filter);
        formData.append("file_type", parameter.file_type);
        const uploaded = await uploadFile("/library/upload/", formData);
        return uploaded.data;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    [filter]
  );
  const gatherMultipleOptionResult = useCallback(
    (profile, extensions = [], switchState) => {
      if (!profile?.parameters) {
        return [];
      }
      if (!extensions) {
        return [];
      }
      let result = [];
      let add = [];
      let remove = [];
      if (switchState) {
        for (let i = 0; i < profile.parameters.length; i++) {
          const parameter = profile.parameters[i];
          if (parameter.type === "multipleMenu") {
            add.push({ id: parameter.id, value: [] });
            remove.push({ id: parameter.id, value: [] });
            const existingExtension = parameter.selected || [];
            add[0]["value"] = extensions.filter(
              (ext) => !existingExtension.includes(ext)
            );
            remove[0]["value"] = existingExtension.filter(
              (ext) => !extensions.includes(ext)
            );
          }
        }
      } else {
        for (let i = 0; i < profile.parameters.length; i++) {
          const parameter = profile.parameters[i];
          if (parameter.type === "multipleMenu") {
            remove.push({ id: parameter.id, value: [] });
            const existingExtension = parameter.selected || [];
            remove[0]["value"] = existingExtension;
          }
        }
      }
      if (add.length > 0 && add[0]["value"].length > 0) {
        result.push({
          profile: profile.id,
          enabled: true,
          parameters: add,
        });
      }
      if (remove.length > 0 && remove[0]["value"].length > 0) {
        result.push({
          profile: profile.id,
          enabled: false,
          parameters: remove,
        });
      }
      return result;
    },
    []
  );

  const gatherProfileParameters = useCallback(
    async (profileParameters) => {
      let parameterValues = [];
      for (let j = 0; j < profileParameters.length; j++) {
        const parameter = profileParameters[j];
        const element = document.getElementById(parameter.id);
        if (parameter?.hidden) {
          continue;
        }
        switch (parameter.type) {
          case "query":
          case "linkedMenu":
            break;
          case "text":
            if (element.value.match(parameter.validator) != null) {
              parameterValues.push({ id: parameter.id, value: element.value });
            }
            break;
          case "localAdmin":
            try {
              let selectedUsers = [];
              let selectedGroups = [];
              element.selectedPeople.map((selected) => {
                if (selected.userPrincipalName) {
                  selectedUsers.push(selected.id);
                } else if (selected.securityIdentifier) {
                  selectedGroups.push(selected.id);
                }
              });
              parameterValues.push({
                id: parameter.id,
                value: { users: selectedUsers, groups: selectedGroups },
              });
            } catch (error) {
              console.error(error);
            }
            break;
          case "file":
            const upload = await uploadFileParameter(parameter);
            if (upload) {
              parameterValues.push({
                id: parameter.id,
                value: upload.id,
              });
            }
            break;
          case "wifiProfiles":
            try {
              const wifiProfiles = JSON.parse(
                sessionStorage.getItem(`wifiProfiles${deviceMode}`)
              );
              parameterValues = [{ id: parameter?.id, value: wifiProfiles }];
            } catch (error) {
              console.error(error);
            }
            break;
          case "ssid":
            try {
              const wifiProfiles = JSON.parse(
                sessionStorage.getItem(`wifiProfiles${deviceMode}`)
              ).filter((p) => p?.state);
              parameterValues = [{ id: parameter?.id, value: wifiProfiles }];
            } catch (error) {
              console.error(error);
            }
            break;
          case "multipleMenu":
            parameterValues.push({
              id: parameter?.id,
              value: element?.value && element.value.split(","),
            });
            break;
          default:
            parameterValues.push({ id: parameter?.id, value: element?.value });
            break;
        }
      }
      return parameterValues;
    },
    [uploadFileParameter, deviceMode]
  );
  const parametersModified = useCallback(
    (profileParameters) => {
      for (let j = 0; j < profileParameters.length; j++) {
        const parameter = profileParameters[j];
        if (parameter?.hidden) {
          continue;
        }
        const element = document.getElementById(parameter.id);
        switch (parameter.type) {
          case "file":
            if (parameter.file_value == "URL") {
              try {
                if (element?.dataset.fileCode == 201) {
                  return true;
                }
                //else {
                //  return false;
                //}
              } catch {}
            }
            //else {
            //  return false;
            //}
            break;
          case "localAdmin":
            try {
              let selectedUsers = [];
              let selectedGroups = [];
              element.selectedPeople.map((selected) => {
                if (selected.userPrincipalName) {
                  selectedUsers.push(selected.id);
                } else if (selected.securityIdentifier) {
                  selectedGroups.push(selected.id);
                }
              });
              if (
                JSON.stringify(selectedGroups.sort()) !=
                  JSON.stringify(parameter.selected?.groups.sort()) ||
                JSON.stringify(selectedUsers.sort()) !=
                  JSON.stringify(parameter.selected?.users.sort())
              ) {
                return true;
              }
            } catch (error) {
              console.error(error);
            }
            break;
          case "query":
          case "linkedMenu":
          case "linkedSolvedMenu":
            break;
          case "wifiProfiles":
            try {
              const wifiProfiles = JSON.parse(
                sessionStorage.getItem(`wifiProfiles${deviceMode}`)
              ) || [];

              if (
                JSON.stringify(wifiProfiles.sort()) !=
                JSON.stringify(parameter?.selected?.sort() || [])
              ) {
                return true;
              }
            } catch (error) {
              console.error(error);
              return false;
            }
            break;

          case "ssid":
            try {
              const wifiProfiles = JSON.parse(
                sessionStorage.getItem(`wifiProfiles${deviceMode}`)
              ).filter((p) => p?.state);
              if (wifiProfiles.length > 0) {
                return true;
              } else {
                return false;
              }
            } catch (error) {
              console.error(error);
              return false;
            }
            break;
          case "multipleMenu":
            if (
              JSON.stringify(parameter?.selected?.sort()) !=
              JSON.stringify(element?.value.split(",").sort())
            ) {
              return true;
            }
            break;
          default:
            if (parameter.selected != element.value) {
              return true;
            }
            //else {
            //  return false;
            //}
            break;
        }
      }
      return false;
    },
    [deviceMode]
  );
  const wifiProfiles = (parameters) => {
    for (let j = 0; j < parameters.length; j++) {
      const parameter = parameters[j];
      if (parameter.type === "wifiProfiles" || parameter.type === "ssid") {
        return true;
      }
    }
    return false;
  };
  const handleSaveProfiles = useCallback(async () => {
    let changes = [];
    let profileList = [];
    switch (deviceMode) {
      case "windows":
        profileList = windowsProfilesStatus;
        break;
      case "ipad":
        profileList = ipadProfilesStatus;
        break;
      case "macos":
        profileList = macosProfilesStatus;
        break;
      default:
        profileList = windowsProfilesStatus;
        break;
    }
    for (let i = 0; i < profileList.length; i++) {
      const profile = profileList[i];
      const profileSwitchState = document.getElementById(profile.id)?.checked; // || null;
      if (wifiProfiles(profile.parameters)) {
        if (parametersModified(profile.parameters)) {
          changes.push({
            profile: profile.id,
            enabled: true,
            parameters: await gatherProfileParameters(profile.parameters),
          });
        }
      } else if (profileSwitchState === null) {
        continue;
      } else if (
        profile.enabled !== profileSwitchState ||
        (profileSwitchState && parametersModified(profile.parameters))
      ) {
        if (profile?.parameters[0]?.type === "multipleMenu") {
          const gatheredParameters = await gatherProfileParameters(
            profile.parameters
          );
          gatherMultipleOptionResult(
            profile,
            gatheredParameters[0].value,
            profileSwitchState
          ).map((p) => changes.push(p));
        } else {
          changes.push({
            profile: profile.id,
            enabled: profileSwitchState,
            parameters: await gatherProfileParameters(profile.parameters),
          });
        }
      }
    }
    const request_body = {
      kind: "asg",
      target: "pol",
      detail: { scope: filter, profiles: changes },
    };
    changes.length > 0 && postApi("/tasks/", request_body);
  }, [
    deviceMode,
    filter,
    gatherMultipleOptionResult,
    gatherProfileParameters,
    ipadProfilesStatus,
    macosProfilesStatus,
    parametersModified,
    windowsProfilesStatus,
  ]);
  return {
    saveProfiles: handleSaveProfiles,
  };
};

export const useProfileStatus = (profileId) => {
  const {
    windowsProfilesStatus,
    macosProfilesStatus,
    ipadProfilesStatus,
    filter,
    refresh,
  } = useContext(PolicyContext);
  const { reload } = useContext(PortalContext);
  const [profileState, setProfileState] = useState(null);
  const [profileInherited, setProfileInherited] = useState(false);
  const [profileEditable, setProfileEditable] = useState(true);
  const [profileRevertable, setProfileRevertable] = useState(false);
  const [profileParameters, setProfileParameters] = useState([]);

  const handleSearchState = useCallback(() => {
    setProfileState(null);
    setProfileInherited(false);
    setProfileEditable(false);
    setProfileRevertable(false);
    setProfileParameters([]);
    const foundProfile = [
      ...windowsProfilesStatus,
      ...ipadProfilesStatus,
      ...macosProfilesStatus,
    ].find((item) => item.id === profileId);
    if (foundProfile) {
      setProfileState(foundProfile?.enabled);
      setProfileInherited(foundProfile?.inherited);
      setProfileEditable(foundProfile?.editable);
      setProfileRevertable(foundProfile?.revertable);
      setProfileParameters(
        foundProfile?.parameters.sort((a, b) => (a?.name > b?.name ? 1 : -1)) ||
          []
      );
    }
  }, [
    ipadProfilesStatus,
    macosProfilesStatus,
    profileId,
    windowsProfilesStatus,
  ]);

  useEffect(() => {
    handleSearchState();
  }, [
    ipadProfilesStatus,
    macosProfilesStatus,
    profileId,
    windowsProfilesStatus,
    filter,
    refresh,
  ]);

  return {
    profileState,
    profileInherited,
    profileEditable,
    profileRevertable,
    profileParameters,
  };
};

export const useOnboarding = (taskId) => {
  const [progress, setProgress] = useState(0);
  const controller = new AbortController(); // Create controller inside `useEffect`
  const { deviceMode } = useContext(PortalContext);
  const {
    setIpadPolicyManagement,
    setMacosPolicyManagement,
    setWindowsPolicyManagement,
  } = useContext(PortalContext);
  const initialize = useCallback(() => {
    getApi(`/tasks/${taskId}/progress/`, {}, controller.signal)
      .then((response) => setProgress(response.data?.progress || 0))
      .catch((e) => {
        console.error(e);
        setProgress(0);
      });
  }, [controller.signal, taskId]);

  useEffect(() => {
    if (progress == 100) {
      if (deviceMode === "windows") {
        setWindowsPolicyManagement(true);
        sessionStorage.setItem("windowsPolicyManagement", true);
      } else if (deviceMode === "ipad") {
        setIpadPolicyManagement(true);
        sessionStorage.setItem("ipadPolicyManagement", true);
      } else if (deviceMode === "macos") {
        setMacosPolicyManagement(true);
        sessionStorage.setItem("macosPolicyManagement", true);
      }
      sessionStorage.removeItem(`onboardTask_${deviceMode}`);
      // window.location.reload();
    }
  }, [
    deviceMode,
    progress,
    setIpadPolicyManagement,
    setMacosPolicyManagement,
    setWindowsPolicyManagement,
  ]);

  useEffect(() => initialize(), [initialize, deviceMode, taskId]);

  return { progress, setProgress };
};

import React, { useRef, useState, useEffect } from "react";
import { ReactComponent as AddLogo } from "../../icons/plus.svg";
import ModalCard, {
  ModalCardBody,
  ModalCardButtons,
  ModalCardTitle,
} from "../../layout/ModalCard";
import WifiCard from "./WifiBaseCard";
import { useTranslation } from "react-i18next";
import { useToggleComponent } from "../../../tools/hooks";
import Field from "../../layout/design/Field";
import Combobox from "../../layout/design/Combobox";
import StandardButton from "../../layout/buttons/StandardButton";
import EditableField from "../../layout/design/EditableField";
import TextboxField from "../../layout/design/TextboxField";

function AddNewProfileModal({ onSubmit, onCancel, wrapper, simple }) {
  const { t } = useTranslation("global");
  const [encryption, setEncryption] = useState("WPA2PSK");
  const [ssid, setSsid] = useState("");
  const [passkey, setPasskey] = useState("");
  const [valid, setValid] = useState(false);
  const handleChangeEncryption = (e) => {
    setEncryption(e.target.value);
  };
  useEffect(() => {
    setValid(
      ssid.match("^.{1,32}$") != null && passkey.match("^.{8,}$") != null
    );
  }, [ssid, passkey, setValid]);
  const handleOnSubmit = () => {
    simple ? onSubmit(ssid, passkey) : onSubmit(encryption, ssid, passkey);
    onCancel();
  };
  return (
    <>
      <ModalCard wrapper={wrapper}>
        <ModalCardTitle>
          {t("parameter.wifiProfiles.modal.add.title")}
        </ModalCardTitle>
        <ModalCardBody>
          <TextboxField
            title={t("parameter.wifiProfiles.network_name")}
            validator="^.{1,32}$"
            value={ssid}
            setValue={setSsid}
          />
          {!simple && (
            <Combobox
              title={t("parameter.wifiProfiles.encryption")}
              options={[
                { label: "WPA3", value: "WPA3SAE" },
                { label: "WPA2", value: "WPA2PSK" },
              ]}
              onChange={handleChangeEncryption}
              defaultValue="WPA2PSK"
              noBlank
            />
          )}
          <TextboxField
            title={t("parameter.wifiProfiles.passkey")}
            validator="^.{8,}$"
            value={passkey}
            setValue={setPasskey}
          />
        </ModalCardBody>
        <ModalCardButtons>
          <StandardButton onClick={onCancel} subtle>
            {t(`pages.device.modal.cancel`)}
          </StandardButton>
          <StandardButton onClick={handleOnSubmit} disabled={!valid}>
            {t(`actions.add`)}
          </StandardButton>
        </ModalCardButtons>
      </ModalCard>
    </>
  );
}

function AddNewProfile({ onSubmit, narrow, simple }) {
  const { t } = useTranslation("global");
  const wrapper = useRef(null);
  const [show, setShow] = useState(false);

  const toggle = () => setShow(!show);
  const hide = () => setShow(false);
  useToggleComponent(wrapper, hide);

  return (
    <>
      <WifiCard onClick={toggle} narrow={narrow}>
        <div className="w-full h-full m-auto flex justify-center place-items-center">
          <AddLogo />
          {t("parameter.wifiProfiles.add_profile")}
        </div>
      </WifiCard>
      {show && (
        <AddNewProfileModal
          onSubmit={onSubmit}
          onCancel={hide}
          wrapper={wrapper}
          simple={simple}
        />
      )}
    </>
  );
}
export default AddNewProfile;

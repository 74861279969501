import React, { useContext, useEffect, useState } from "react";
import { useProfileStatus } from "../../../hooks/usePolicy";
import { PolicyContext } from "../policyContext";
import { PortalContext } from "../../../PortalContext";
import { LoadingWifiProfileCard } from "../wifiProfiles/LoadingProfileCard";
import AddNewProfile from "../wifiProfiles/AddNewProfile";
import { useTranslation } from "react-i18next";
import WifiCard from "../wifiProfiles/WifiBaseCard";
import BlackTag from "../../layout/design/BlackTag";
import EditProfile from "../wifiProfiles/EditProfileModal";
import DeleteProfile from "../wifiProfiles/DeleteProfileModal";
import Field from "../../layout/design/Field";
import Tooltip from "../../layout/tooltip/Tooltip";

function WifiProfileApple({ profileId }) {
  const { profileState, profileParameters, profileEditable } =
    useProfileStatus(profileId);
  const { editMode, setEditMode } = useContext(PolicyContext);
  const { deviceMode } = useContext(PortalContext);
  const [profileList, setProfileList] = useState([]);
  useEffect(() => {
    setProfileList(profileParameters[0]?.selected || []);
  }, [profileParameters]);
  useEffect(() => {
    sessionStorage.setItem(
      `wifiProfiles${deviceMode}`,
      JSON.stringify(profileList)
    );
  }, [profileList, deviceMode]);
  const handleAddProfile = (ssid, passkey) => {
    setProfileList((p) => [
      {
        id: Math.random().toString(36).slice(2, 11),
        ssid: ssid,
        passkey: passkey,
        state: "new",
      },
      ...p,
    ]);
    if (!editMode) {
      setEditMode(true);
    }
  };
  const handleRemoveProfile = (id) => {
    setProfileList((p) =>
      p.map((i) =>
        i.id === id
          ? { ssid: i.ssid, id: id, state: "delete" } // edit ssid
          : i
      )
    );
    if (!editMode) {
      setEditMode(true);
    }
  };
  const handleEditProfile = (id, ssid, passkey = null) => {
    setProfileList((p) =>
      p.map((i) =>
        i.id === id
          ? passkey
            ? ssid === i.ssid
              ? {
                  ssid: ssid,
                  passkey: passkey,
                  id: id,
                  state: "passkey",
                } // edit passkey
              : {
                  ssid: ssid,
                  passkey: passkey,
                  id: id,
                  state: "edit",
                } // edit both
            : { ssid: ssid, id: id, state: "ssid" } // edit ssid
          : i
      )
    );
    if (!editMode) {
      setEditMode(true);
    }
  };

  return (
    <>
      <div className="w-full grid grid-cols-2 gap-2  ">
        {profileState == null ? (
          <LoadingWifiProfileCard />
        ) : (
          profileEditable && (
            <AddNewProfile onSubmit={handleAddProfile} narrow simple />
          )
        )}
        {profileList.map(
          (profile) =>
            profile?.state !== "delete" && (
              <Tooltip
                title={
                  !profileEditable && "pages.policy.room_category_advisory"
                }
                key={profile.id}
              >
                <WifiProfileCardApple
                  profile={profile}
                  removeProfile={handleRemoveProfile}
                  editProfile={handleEditProfile}
                  editable={profileEditable}
                />
              </Tooltip>
            )
        )}
      </div>
    </>
  );
}

export default WifiProfileApple;

export function WifiProfileCardApple({
  profile,
  removeProfile,
  editProfile,
  editable,
}) {
  const { t } = useTranslation("global");
  const { editMode } = useContext(PolicyContext);
  return (
    <WifiCard narrow>
      <div className="flex justify-between">
        <div className="font-semibold">{profile?.ssid}</div>
        <div className="flex space-x-2">
          {editMode &&
            (profile?.state === "new" ? (
              <BlackTag>{t("words.new")}</BlackTag>
            ) : profile?.state === "edit" ||
              profile?.state === "ssid" ||
              profile?.state === "passkey" ? (
              <BlackTag>{t("words.edited")}</BlackTag>
            ) : (
              <></>
            ))}
          {editable && (
            <>
              <EditProfile onSubmit={editProfile} profile={profile} simple />
              <DeleteProfile onSubmit={removeProfile} profile={profile} />
            </>
          )}
        </div>
      </div>
      <div className="w-full  flex">
        <div className="w-[120px] text-fgray-300 my-auto text-xs">
          {t("parameter.wifiProfiles.network_name")}
        </div>
        <div className="w-[220px] text-fblack">
          <Field value={profile?.ssid} label className="text-sm" />
        </div>
      </div>

      <div className="w-full  flex">
        <div className="w-[120px] text-fgray-300 my-auto text-xs">
          {t("parameter.wifiProfiles.passkey")}
        </div>
        <div className="w-[220px] text-fblack">
          <Field value={profile?.passkey} password />
        </div>
      </div>
    </WifiCard>
  );
}

import React from 'react'
import { useTranslation } from "react-i18next";

function NewGroup({type,info}) {
  const { t } = useTranslation("global");
  return (
    <div className={ `animate-pulse border rounded-2xl items-center text-center hover:text-forange-400 hover:border-forange-400 hover:font-medium font-normal active:bg-forange-400 active:text-white`}>
        <div className='align-middle text-center items-center'>
          {type === "rom" ?
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-12 w-12 mx-auto my-4'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
            strokeWidth={1}>
            <path d='M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z' />
          </svg>:
          <svg
          xmlns='http://www.w3.org/2000/svg'
          className='h-12 w-12 mx-auto my-4'
          fill='none'
          viewBox='0 0 24 24'
          stroke='currentColor'
          strokeWidth={1}>
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10'
          />
        </svg>}
        </div>
        <p className='px-4 pb-3 list-none items-center  active:rounded'>
        <span className="text-sm text-fgray-300">{t("pages.group.working_on")}</span>
        
        {t(info)}
        </p>
      </div>
  )
}

export default NewGroup
import React, { useRef, useState } from "react";
import { ReactComponent as DeleteLogo } from "../../icons/remove.svg";
import Tooltip from "../../layout/tooltip/Tooltip";
import { useTranslation } from "react-i18next";
import ModalCard, {
  ModalCardBody,
  ModalCardButtons,
  ModalCardTitle,
} from "../../layout/ModalCard";
import { useToggleComponent } from "../../../tools/hooks";
import StandardButton from "../../layout/buttons/StandardButton";
function DeleteProfile({ onSubmit, profile}) {
  const { t } = useTranslation("global");
  const [show, setShow] = useState(false);
  const toggle = () => setShow(!show);
  const hide = () => setShow(false);
  const wrapper = useRef(null);
  useToggleComponent(wrapper, hide);
  const handleOnRemove = () => {
    onSubmit(profile.id);
    hide();
  };
  return (
    <>
      <Tooltip title={t("parameter.wifiProfiles.delete_profile")}>
        <DeleteLogo
          className="w-6 h-6 hover:text-fstatus-400"
          onClick={toggle}
        />
      </Tooltip>
      {show && (
        <DeleteProfileModal
          wrapper={wrapper}
          onSubmit={handleOnRemove}
          onCancel={hide}
          profile={profile}
        />
      )}
    </>
  );
}

function DeleteProfileModal({ wrapper, onSubmit, onCancel, profile }) {
  const { t } = useTranslation("global");
  return (
    <ModalCard wrapper={wrapper}>
      <ModalCardTitle>
        {t("parameter.wifiProfiles.modal.delete.title")}
      </ModalCardTitle>
      <ModalCardBody>
        {t("parameter.wifiProfiles.modal.delete.body", { displayName: profile.ssid })}
      </ModalCardBody>
      <ModalCardButtons>
        <StandardButton onClick={onCancel} subtle>
          {t(`actions.cancel`)}
        </StandardButton>
        <StandardButton onClick={onSubmit} destructive>
          {t(`actions.delete`)}
        </StandardButton>
      </ModalCardButtons>
    </ModalCard>
  );
}

export default DeleteProfile;

/* eslint-disable default-case */
import React from "react";
import { useTranslation } from "react-i18next";

function TaskDescription({ task }) {
  const { t } = useTranslation("global");
  let amount = task.detail.length;

  switch (task.kind) {
    case "create":
      switch (task.target) {
        case "application":
          return (
            <p className="line-clamp-3">
              {t("tasks.create.app", {
                count: amount,
                displayName: task.display?.appName || task.display?.displayName,
                appName: task.display?.appName || task.display?.displayName,
              })}
            </p>
          );
          break;
        case "room":
          return (
            <p className="line-clamp-3">
              {t("tasks.create.room", { target: task.display.target })}
            </p>
          );
          break;
        case "category":
          return (
            <p className="line-clamp-3">
              {t("tasks.create.category", { target: task.display.target })}
            </p>
          );
          break;
        case "device":
          return <p className="line-clamp-3">{t("tasks.create.device")}</p>;
          break;
        default:
          return (
            <p className="line-clamp-3">
              {t(
                `tasks.${task.kind}.${task.target}`,
                `tasks.${task.kind}.${task.target}`,
                {
                  ...task.display,
                  target: t(
                    `categories.name.${task.display.target}`,
                    task.display.target
                  ),
                }
              )}
            </p>
          );
          break;
      }
      break;
    case "delete":
      switch (task.target) {
        case "application":
          return (
            <p className="line-clamp-3">
              {t("tasks.delete.app", {
                app: task.display.app,
                count: task.display.count,
              })}
            </p>
          );
        case "room":
          return (
            <p className="line-clamp-3">
              {t("tasks.delete.room", { target: task.display.target })}
            </p>
          );
        case "category":
          return (
            <p className="line-clamp-3">
              {t("tasks.delete.category", { target: task.display.target })}
            </p>
          );
        case "device":
          if (task.display.amount === 1) {
            return (
              <p className="line-clamp-3">
                {t("tasks.delete.device", {
                  target: task.display.device,
                  count: 1,
                })}
              </p>
            );
          } else {
            return (
              <p className="line-clamp-3">
                {t("tasks.delete.device", {
                  target: task.display.amount,
                  count: 2,
                })}
              </p>
            );
          }
        case "settings":
          return (
            <p className="line-clamp-3">
              {t("tasks.delete.administrator", {
                target: task.display.target,
                count: task.display.count,
              })}
            </p>
          );
          break;
        default:
          return (
            <p className="line-clamp-3">
              {t(
                `tasks.${task.kind}.${task.target}`,
                `tasks.${task.kind}.${task.target}`,
                {
                  ...task.display,
                  target: t(
                    `categories.name.${task.display.target}`,
                    task.display.target
                  ),
                }
              )}
            </p>
          );
          break;
      }
      break;
    case "assign":
      switch (task.target) {
        case "application":
          if (task.display.type === "1:1") {
            return (
              <p className="line-clamp-3">
                {t("tasks.assign.app", {
                  app: task.display.app,
                  target: t(
                    `categories.name.${task.display.target}`,
                    task.display.target
                  ),
                  count: 1,
                })}
              </p>
            );
          } else {
            return (
              <p className="line-clamp-3">
                {t("tasks.assign.app", {
                  app: task.display.app,
                  target: t(
                    `categories.name.${task.display.target}`,
                    task.display.target
                  ),
                  count: 2,
                })}
              </p>
            );
          }
        case "policy":
          return (
            <p className="line-clamp-3">
              {t("tasks.assign.policy", {
                target: t(
                  `categories.name.${task.display.target}`,
                  task.display.target
                ),
              })}
            </p>
          );
        case "device":
          switch (task.display.type) {
            case "1:1c":
              return (
                <p className="line-clamp-3">
                  {t("tasks.assign.device_1c", {
                    device: task.display.device,
                    target: t(
                      `categories.name.${task.display.category}`,
                      task.display.category
                    ),
                    count: 1,
                  })}
                </p>
              );
            case "1:1r":
              return (
                <p className="line-clamp-3">
                  {t("tasks.assign.device_1r", {
                    device: task.display.device,
                    target: task.display.room,
                    count: 1,
                  })}
                </p>
              );
            case "n:1c":
              return (
                <p className="line-clamp-3">
                  {t("tasks.assign.device_1c", {
                    device: task.display.device,
                    target: t(
                      `categories.name.${task.display.category}`,
                      task.display.category
                    ),
                    count: 2,
                  })}
                </p>
              );
            case "n:1r":
              return (
                <p className="line-clamp-3">
                  {t("tasks.assign.device_1r", {
                    device: task.display.device,
                    target: task.display.room,
                    count: 2,
                  })}
                </p>
              );
            case "1:1c1r":
              return (
                <p className="line-clamp-3">
                  {t("tasks.assign.device_1c1r", {
                    device: task.display.device,
                    category: t(
                      `categories.name.${task.display.category}`,
                      task.display.category
                    ),
                    room: task.display.room,
                    count: 1,
                  })}
                </p>
              );
            case "n:1c1r":
              return (
                <p className="line-clamp-3">
                  {t("tasks.assign.device_1c1r", {
                    device: task.display.device,
                    category: t(
                      `categories.name.${task.display.category}`,
                      task.display.category
                    ),
                    room: task.display.room,
                    count: 2,
                  })}
                </p>
              );
            default:
              return <p className="line-clamp-3">{task.status}</p>;
          }
        case "settings":
          return (
            <p className="line-clamp-3">
              {t("tasks.assign.administrator", {
                target: task.display.target,
                count: task.display.count,
              })}
            </p>
          );
          break;
        default:
          return (
            <p className="line-clamp-3">
              {t(
                `tasks.${task.kind}.${task.target}`,
                `tasks.${task.kind}.${task.target}`,
                {
                  ...task.display,
                  target: t(
                    `categories.name.${task.display.target}`,
                    task.display.target
                  ),
                }
              )}
            </p>
          );
          break;
      }
      break;
    case "unassign":
      switch (task.target) {
        case "application":
          if (task.display.type === "1:1") {
            return (
              <p className="line-clamp-3">
                {t("tasks.unassign.app", {
                  app: task.display.app,
                  target: t(
                    `categories.name.${task.display.target}`,
                    task.display.target
                  ),
                  count: 1,
                })}
              </p>
            );
          } else {
            return (
              <p className="line-clamp-3">
                {t("tasks.unassign.app", {
                  app: task.display.app,
                  target: t(
                    `categories.name.${task.display.target}`,
                    task.display.target
                  ),
                  count: 2,
                })}
              </p>
            );
          }
        case "room":
          return (
            <p className="line-clamp-3">
              {t("tasks.unassign.room", { count: amount })}
            </p>
          );
        case "category":
          return (
            <p className="line-clamp-3">
              {t("tasks.unassign.category", { count: amount })}
            </p>
          );
        case "device":
          return (
            <p className="line-clamp-3">
              {t("tasks.unassign.device", { count: amount })}
            </p>
          );
          break;
        default:
          return (
            <p className="line-clamp-3">
              {t(
                `tasks.${task.kind}.${task.target}`,
                `tasks.${task.kind}.${task.target}`,
                {
                  ...task.display,
                  target: t(
                    `categories.name.${task.display.target}`,
                    task.display.target
                  ),
                }
              )}
            </p>
          );
          break;
      }
      break;
    case "unmanage":
      switch (task.target) {
        case "application":
          if (task.display.type === "n") {
            return (
              <p className="line-clamp-3">
                {t("tasks.unmanage.app", { app: task.display.app, count: 2 })}
              </p>
            );
          } else {
            return (
              <p className="line-clamp-3">
                {t("tasks.unmanage.app", { app: task.display.app, count: 1 })}
              </p>
            );
          }
        case "room":
          return (
            <p className="line-clamp-3">
              {t("tasks.unmanage.room", { count: amount })}
            </p>
          );
        case "category":
          return (
            <p className="line-clamp-3">
              {t("tasks.unmanage.category", { count: amount })}
            </p>
          );
        case "device":
          return (
            <p className="line-clamp-3">
              {t("tasks.unmanage.device", { count: amount })}
            </p>
          );
        case "settings":
          return <p className="line-clamp-3">{t("tasks.offboard.setting")}</p>;
        case "policy":
          return (
            <p className="line-clamp-3">
              {t("tasks.unmanage.policy", {
                target: task.display?.target,
                displayName: task.display?.target,
              })}
            </p>
          );
          break;
        default:
          return (
            <p className="line-clamp-3">
              {t(
                `tasks.${task.kind}.${task.target}`,
                `tasks.${task.kind}.${task.target}`,
                {
                  ...task.display,
                  target: t(
                    `categories.name.${task.display.target}`,
                    task.display.target
                  ),
                }
              )}
            </p>
          );
          break;
      }
      break;
    case "rename":
      switch (task.target) {
        case "application":
          return (
            <p className="line-clamp-3">
              {t("tasks.rename.app", { count: amount })}
            </p>
          );
        case "room":
          return (
            <p className="line-clamp-3">
              {t("tasks.rename.room", {
                pre: task.display.pre,
                new: task.display.new,
              })}
            </p>
          );
        case "category":
          return (
            <p className="line-clamp-3">
              {t("tasks.rename.category", {
                pre: task.display.pre,
                new: task.display.new,
              })}
            </p>
          );
        case "device":
          return task.display.newName ? (
            <p className="line-clamp-3">
              {t("tasks.rename.device", {
                count: amount,
                oldName: task.display.oldName,
                newName: task.display.newName,
              })}
            </p>
          ) : (
            <p className="line-clamp-3">
              {t("tasks.rename.device_notes", {
                count: amount,
                oldName: task.display.oldName,
              })}
            </p>
          );
        case "settings":
          return (
            <p className="line-clamp-3">
              {t("tasks.edit.setting", {
                count: task.display.count,
                target: task.display.target,
                role: task.display.role,
              })}
            </p>
          );
          break;
        default:
          return (
            <p className="line-clamp-3">
              {t(
                `tasks.${task.kind}.${task.target}`,
                `tasks.${task.kind}.${task.target}`,
                {
                  ...task.display,
                  target: t(
                    `categories.name.${task.display.target}`,
                    task.display.target
                  ),
                }
              )}
            </p>
          );
          break;
      }
      break;
    case "sync":
      switch (task.target) {
        case "application":
          return (
            <p className="line-clamp-3">
              {t("tasks.sync.app", { count: amount })}
            </p>
          );
        case "room":
          return (
            <p className="line-clamp-3">
              {t("tasks.sync.room", { count: amount })}
            </p>
          );
        case "category":
          return (
            <p className="line-clamp-3">
              {t("tasks.sync.category", { count: amount })}
            </p>
          );
        case "device":
          if (task.display.amount === 1) {
            return (
              <p className="line-clamp-3">
                {t("tasks.sync.device", {
                  target: task.display.device,
                  count: 1,
                })}
              </p>
            );
          } else {
            return (
              <p className="line-clamp-3">
                {t("tasks.sync.device", {
                  target: task.display.amount,
                  count: 2,
                })}
              </p>
            );
          }
          break;
        default:
          return (
            <p className="line-clamp-3">
              {t(
                `tasks.${task.kind}.${task.target}`,
                `tasks.${task.kind}.${task.target}`,
                {
                  ...task.display,
                  target: t(
                    `categories.name.${task.display.target}`,
                    task.display.target
                  ),
                }
              )}
            </p>
          );
          break;
      }
      break;
    case "reset":
      switch (task.target) {
        case "application":
          return (
            <p className="line-clamp-3">
              {t("tasks.reset.app", { count: amount })}
            </p>
          );
        case "room":
          return (
            <p className="line-clamp-3">
              {t("tasks.reset.room", { count: amount })}
            </p>
          );
        case "category":
          return (
            <p className="line-clamp-3">
              {t("tasks.reset.category", { count: amount })}
            </p>
          );
        case "device":
          if (task.display.amount === 1) {
            return (
              <p className="line-clamp-3">
                {t("tasks.reset.device", {
                  target: task.display.device,
                  count: 1,
                })}
              </p>
            );
          } else {
            return (
              <p className="line-clamp-3">
                {t("tasks.reset.device", {
                  target: task.display.amount,
                  count: 2,
                })}
              </p>
            );
          }
          break;
        default:
          return (
            <p className="line-clamp-3">
              {t(
                `tasks.${task.kind}.${task.target}`,
                `tasks.${task.kind}.${task.target}`,
                {
                  ...task.display,
                  target: t(
                    `categories.name.${task.display.target}`,
                    task.display.target
                  ),
                }
              )}
            </p>
          );
          break;
      }
      break;
    case "reboot":
      switch (task.target) {
        case "application":
          return (
            <p className="line-clamp-3">
              {t("tasks.reboot.app", { count: amount })}
            </p>
          );
        case "room":
          return (
            <p className="line-clamp-3">
              {t("tasks.reboot.room", { count: amount })}
            </p>
          );
        case "category":
          return (
            <p className="line-clamp-3">
              {t("tasks.reboot.category", { count: amount })}
            </p>
          );
        case "device":
          if (task.display.amount === 1) {
            return (
              <p className="line-clamp-3">
                {t("tasks.reboot.device", {
                  target: task.display.device,
                  count: 1,
                })}
              </p>
            );
          } else {
            return (
              <p className="line-clamp-3">
                {t("tasks.reboot.device", {
                  target: task.display.amount,
                  count: 2,
                })}
              </p>
            );
          }
          break;
        default:
          return (
            <p className="line-clamp-3">
              {t(
                `tasks.${task.kind}.${task.target}`,
                `tasks.${task.kind}.${task.target}`,
                {
                  ...task.display,
                  target: t(
                    `categories.name.${task.display.target}`,
                    task.display.target
                  ),
                }
              )}
            </p>
          );
          break;
      }
      break;
    case "onboard":
      switch (task.target) {
        case "policy":
          return <p className="line-clamp-3">{t("tasks.onboard.policy")}</p>;
          break;
        case "device":
          return <p className="line-clamp-3">{t("tasks.create.device")}</p>;
          break;
        default:
          return (
            <p className="line-clamp-3">
              {t(
                `tasks.${task.kind}.${task.target}`,
                `tasks.${task.kind}.${task.target}`,
                {
                  ...task.display,
                  target: t(
                    `categories.name.${task.display.target}`,
                    task.display.target
                  ),
                }
              )}
            </p>
          );
          break;
      }
    case "unlock":
      return (
        <p className="line-clamp-3">
          {t(
            `tasks.${task.kind}.${task.target}`,
            `tasks.${task.kind}.${task.target}`,
            {
              count: task.display.amount,
              target: t(
                `categories.name.${task.display.target}`,
                task.display.device
              ),
            }
          )}
        </p>
      );
      break;
    case "update":
      switch (task.target) {
        case "application":
          return (
            <p className="line-clamp-3">
              {t("tasks.update.app", {
                count: amount,
                appName: task.display.appName,
                displayName: task.display.appName,
              })}
            </p>
          );
          break;
        default:
          return (
            <p className="line-clamp-3">
              {t(
                `tasks.${task.kind}.${task.target}`,
                `tasks.${task.kind}.${task.target}`,
                {
                  ...task.display,
                  target: t(
                    `categories.name.${task.display.target}`,
                    task.display.target
                  ),
                }
              )}
            </p>
          );
          break;
      }
      break;
    default:
      return (
        <p className="line-clamp-3">
          {t(
            `tasks.${task.kind}.${task.target}`,
            `tasks.${task.kind}.${task.target}`,
            {
              ...task.display,
              target: t(
                `categories.name.${task.display.target}`,
                task.display.target
              ),
            }
          )}
        </p>
      );
      break;
  }
}

export default TaskDescription;

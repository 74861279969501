import React from "react";
import Parameter from "./Parameter";
import { useProfileStatus } from "../../hooks/usePolicy";

function ProfileParameters({ profileId }) {
  const { profileParameters } = useProfileStatus(profileId);
  return (
    <>
      <div className="w-3/4">
        {profileParameters.map((parameter, index) => (
          <Parameter
            key={`${index}param${parameter.id}`}
            profileId={profileId}
            definition={parameter}
          />
        ))}
      </div>
    </>
  );
}

export default ProfileParameters;

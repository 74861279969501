import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PolicyContext } from "../policyContext";
import Field from "../../layout/design/Field";
import { useProfileStatus } from "../../../hooks/usePolicy";
import WifiCard from "../wifiProfiles/WifiBaseCard";
import { LoadingWifiProfileCard } from "../wifiProfiles/LoadingProfileCard";
import AddNewProfile from "../wifiProfiles/AddNewProfile";
import DeleteProfile from "../wifiProfiles/DeleteProfileModal";
import EditProfile from "../wifiProfiles/EditProfileModal";
import BlackTag from "../../layout/design/BlackTag";
import { PortalContext } from "../../../PortalContext";
import Tooltip from "../../layout/tooltip/Tooltip";
function WifiProfileWindows({ profileId }) {
  const { profileState, profileParameters, profileEditable } =
    useProfileStatus(profileId);
  const { editMode, setEditMode } = useContext(PolicyContext);
  const { deviceMode } = useContext(PortalContext);
  const [profileList, setProfileList] = useState([]);
  useEffect(() => {
    setProfileList(profileParameters[0]?.selected || []);
  }, [profileParameters]);
  useEffect(() => {
    sessionStorage.setItem(
      `wifiProfiles${deviceMode}`,
      JSON.stringify(profileList)
    );
  }, [deviceMode, profileList]);
  const handleAddProfile = (encryption, ssid, passkey) => {
    setProfileList((p) => [
      {
        id: Math.random().toString(36).slice(2, 11),
        encryption: encryption,
        ssid: ssid,
        passkey: passkey,
        state: "new",
      },
      ...p,
    ]);
    if (!editMode) {
      setEditMode(true);
    }
  };
  const handleRemoveProfile = (profileId) => {
    setProfileList((p) => p.filter((i) => i.id !== profileId));
    if (!editMode) {
      setEditMode(true);
    }
  };
  const handleEditProfile = (profileId, encryption, ssid, passkey) => {
    setProfileList((p) =>
      p.map((i) =>
        i.id === profileId
          ? {
              encryption: encryption,
              ssid: ssid,
              passkey: passkey,
              state:
                encryption === i.encryption &&
                ssid === i.ssid &&
                passkey === i.passkey
                  ? null
                  : "edit",
            }
          : i
      )
    );
    if (!editMode) {
      setEditMode(true);
    }
  };
  return (
    <>
      <div className="w-full grid grid-cols-2 gap-2  ">
        {profileState == null ? (
          <LoadingWifiProfileCard />
        ) : (
          profileEditable && <AddNewProfile onSubmit={handleAddProfile} />
        )}
        {profileList.map((profile) => (
          <Tooltip
            title={!profileEditable && "pages.policy.room_category_advisory"}
            key={profile.id}
          >
            <WifiProfileCard
              profile={profile}
              removeProfile={handleRemoveProfile}
              editProfile={handleEditProfile}
              editable={profileEditable}
            />
          </Tooltip>
        ))}
      </div>
    </>
  );
}

export default WifiProfileWindows;

export function WifiProfileCard({
  profile,
  removeProfile,
  editProfile,
  editable,
}) {
  const { t } = useTranslation("global");
  const { editMode } = useContext(PolicyContext);
  return (
    <WifiCard>
      <div className="flex justify-between">
        <div className="font-semibold">{profile?.ssid}</div>
        <div className="flex space-x-2">
          {editMode &&
            (profile?.state === "new" ? (
              <BlackTag>{t("words.new")}</BlackTag>
            ) : profile?.state === "edit" ? (
              <BlackTag>{t("words.edited")}</BlackTag>
            ) : (
              <></>
            ))}
          {editable && (
            <>
              <EditProfile onSubmit={editProfile} profile={profile} />
              <DeleteProfile onSubmit={removeProfile} profile={profile} />
            </>
          )}
        </div>
      </div>
      <div className="w-full  flex">
        <div className="w-[120px] text-fgray-300 my-auto text-xs">
          {t("parameter.wifiProfiles.network_name")}
        </div>
        <div className="w-[220px] text-fblack">
          <Field value={profile?.ssid} label className="text-sm" />
        </div>
      </div>
      <div className="w-full  flex">
        <div className="w-[120px] text-fgray-300 my-auto text-xs">
          {t("parameter.wifiProfiles.encryption")}
        </div>
        <div className="w-[220px] text-fblack">
          <Field value={profile?.encryption} label />
        </div>
      </div>
      <div className="w-full  flex">
        <div className="w-[120px] text-fgray-300 my-auto text-xs">
          {t("parameter.wifiProfiles.passkey")}
        </div>
        <div className="w-[220px] text-fblack">
          <Field value={profile?.passkey} password />
        </div>
      </div>
    </WifiCard>
  );
}

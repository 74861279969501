import React from "react";

function BlackTag({ children }) {
  return (
    <span className="bg-black text-white text-xs font-bold px-2 py-1 rounded-md uppercase">
      {children}
    </span>
  );
}

export default BlackTag;

import React, { useState } from "react";
import ComboboxOptions from "../../layout/design/ComboboxOptions";

function MultipleOptionMenu({ profileId, definition,disabled }) {
  const [selectedList, setSelectedList] = useState(definition?.selected || []);
  const handleOnChangeSelected = (selected) => setSelectedList(selected);
  return (
    <>
      <input type="text" id={definition.id} hidden value={selectedList} onChange={()=>{}} />
      <ComboboxOptions
        options={definition?.options}
        selected={selectedList}
        onChange={handleOnChangeSelected}
        disabled={disabled}
      />
    </>
  );
}

export default MultipleOptionMenu;

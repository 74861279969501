import React, { useContext } from "react";
import PageTitle from "../components/layout/PageTitle";
import { useTranslation } from "react-i18next";
import ActionButton from "../components/layout/buttons/ActionButton";
import { ReactComponent as SaveIcon } from "../components/icons/save.svg";
import { ReactComponent as CancelIcon } from "../components/icons/cancelX.svg";
import { ReactComponent as EditIcon } from "../components/icons/edit.svg";
import {
  PolicyContext,
  PolicyContextProvider,
} from "../components/policy/policyContext";
import { PortalContext } from "../PortalContext";
import PageContent, { PageContentBody } from "../components/layout/PageContent";
import LoadingTable from "../components/layout/loading/LoadingTable";
import DeviceModeBar from "../components/layout/DeviceModeBar";
import IpadOnboarding from "../components/device/IpadOnboarding";
import MacosOnboarding from "../components/device/MacosOnboarding";
import Sidebar from "../components/layout/Sidebar";
import ObjectList from "../components/policy/ObjectList";
import PolicyMgmtOnboard from "../components/policy/PolicyMgmtOnboard";
import { usePolicy } from "../hooks/usePolicy";

function PolicyPage() {
  const { t } = useTranslation("global");
  const {
    deviceMode,
    windowsPolicyManagement,
    ipadPolicyManagement,
    macosPolicyManagement,
    ipadOnboarded,
    macosOnboarded,
  } = useContext(PortalContext);
  const { filter, setFilter, editMode, setEditMode, refreshProfiles } =
    useContext(PolicyContext);
  const { saveProfiles } = usePolicy();
  const toggleCancel = () => {
    setEditMode(false);
    refreshProfiles();
  };
  const savePolicies = () => {
    setEditMode(false);
    saveProfiles();
  };
  const toggleEdit = () => setEditMode((p) => !p);
  return (
    <>
      <PageTitle title={t("pages.policy.title")}>
        <div className="justify-end">
          {(deviceMode == "windows" && windowsPolicyManagement) ||
          (deviceMode == "ipad" && ipadPolicyManagement) ||
          (deviceMode == "macos" && macosPolicyManagement) ? (
            editMode ? (
              <span className="flex space-x-2">
                <ActionButton Icon={CancelIcon} onClick={toggleCancel} subtle>
                  {t("words.cancel")}
                </ActionButton>
                <ActionButton Icon={SaveIcon} onClick={savePolicies} subtle>
                  {t("pages.policy.save")}
                </ActionButton>
              </span>
            ) : (
              <ActionButton Icon={EditIcon} onClick={toggleEdit} subtle>
                {t("pages.policy.edit")}
              </ActionButton>
            )
          ) : (
            <></>
          )}
        </div>
      </PageTitle>

      {windowsPolicyManagement == null ||
      ipadPolicyManagement == null ||
      macosPolicyManagement == null ? (
        <PageContent>
          <LoadingTable />
        </PageContent>
      ) : deviceMode == "ipad" && ipadOnboarded !== true ? (
        <>
          <PageContent full>
            <DeviceModeBar target="policy" />
            <IpadOnboarding target="policy" />
          </PageContent>
        </>
      ) : deviceMode == "macos" && macosOnboarded !== true ? (
        <>
          <PageContent full>
            <DeviceModeBar target="policy" />
            <MacosOnboarding target="policy" />
          </PageContent>
        </>
      ) : (deviceMode == "windows" && windowsPolicyManagement) ||
        (deviceMode == "ipad" && ipadPolicyManagement == true) ||
        (deviceMode == "macos" && macosPolicyManagement == true) ? (
        <>
          <PageContent sidebar>
            <Sidebar
              filter={filter}
              setFilter={setFilter}
              globalId="99999999-9999-9999-9999-999999999999"
            />
            <PageContentBody>
              <DeviceModeBar target="policy" />
              <ObjectList />
            </PageContentBody>
          </PageContent>
        </>
      ) : (
        <PolicyMgmtOnboard />
      )}
    </>
  );
}

function Policy() {
  return (
    <PolicyContextProvider>
      <PolicyPage />
    </PolicyContextProvider>
  );
}

export default Policy;

import LoadingText from "../../layout/loading/LoadingText";
import WifiCard from "./WifiBaseCard";
import { useTranslation } from 'react-i18next';

export function LoadingWifiProfileCard() {
  const { t } = useTranslation("global");
  return (<>
      <WifiCard>
        <div className="w-full h-full m-auto space-y-4">
          <div className="flex justify-between">
            <div className="h-5 w-64 rounded content-center bg-fgray-200" />
            <div className="h-5 w-16 rounded content-center bg-fgray-200" />
          </div>

          <LoadingText />
          <LoadingText />
          <LoadingText />
        </div>
      </WifiCard><WifiCard>
        <div className="w-full h-full m-auto space-y-4">
          <div className="flex justify-between">
            <div className="h-5 w-64 rounded content-center bg-fgray-200" />
            <div className="h-5 w-16 rounded content-center bg-fgray-200" />
          </div>

          <LoadingText />
          <LoadingText />
          <LoadingText />
        </div>
      </WifiCard>
    </>
  );
}
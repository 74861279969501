import React, { useState, useRef, useEffect } from "react";
import { ReactComponent as EditLogo } from "../../icons/edit.svg";
import Tooltip from "../../layout/tooltip/Tooltip";
import { useTranslation } from "react-i18next";
import { useToggleComponent } from "../../../tools/hooks";
import TextboxField from "../../layout/design/TextboxField";

import ModalCard, {
  ModalCardBody,
  ModalCardButtons,
  ModalCardTitle,
} from "../../layout/ModalCard";
import StandardButton from "../../layout/buttons/StandardButton";
import Combobox from "../../layout/design/Combobox";
function EditProfileModal({ onSubmit, onCancel, profile, wrapper, simple }) {
  const { t } = useTranslation("global");
  const [encryption, setEncryption] = useState(
    profile?.encryption || "WPA2PSK"
  );
  const [ssid, setSsid] = useState(profile?.ssid || "");
  const [passkey, setPasskey] = useState(profile?.passkey || "");
  const [valid, setValid] = useState(true);
  useEffect(() => {
    setValid(
      ssid.match("^.{1,32}$") != null && passkey.match("^.{8,}$") != null
    );
  }, [ssid, passkey, setValid]);
  const handleChangeEncryption = (e) => {
    setEncryption(e.target.value);
  };
  const handleOnSubmit = () => {
    simple
      ? passkey === profile.passkey
        ? onSubmit(profile.id, ssid) // just SSID
        : onSubmit(profile.id, ssid, passkey) // passkey changed
      : onSubmit(profile.id, encryption, ssid, passkey);
    onCancel();
  };
  return (
    <>
      <ModalCard wrapper={wrapper}>
        <ModalCardTitle>
          {t("parameter.wifiProfiles.modal.edit.title")}
        </ModalCardTitle>
        <ModalCardBody>
          <TextboxField
            title={t("parameter.wifiProfiles.network_name")}
            validator="^.{1,32}$"
            value={ssid}
            setValue={setSsid}
          />
          {!simple && (
            <Combobox
              title={t("parameter.wifiProfiles.encryption")}
              options={[
                { label: "WPA3", value: "WPA3SAE" },
                { label: "WPA2", value: "WPA2PSK" },
              ]}
              onChange={handleChangeEncryption}
              defaultValue={encryption}
              noBlank
            />
          )}
          <TextboxField
            title={t("parameter.wifiProfiles.passkey")}
            validator="^.{8,}$"
            value={passkey}
            setValue={setPasskey}
          />
        </ModalCardBody>
        <ModalCardButtons>
          <StandardButton onClick={onCancel} subtle>
            {t(`pages.device.modal.cancel`)}
          </StandardButton>
          <StandardButton onClick={handleOnSubmit} disabled={!valid}>
            {t(`actions.confirm`)}
          </StandardButton>
        </ModalCardButtons>
      </ModalCard>
    </>
  );
}
function EditProfile({ onSubmit, profile, simple }) {
  const { t } = useTranslation("global");
  const [show, setShow] = useState(false);
  const wrapper = useRef(null);
  const toggle = () => setShow(!show);
  const hide = () => setShow(false);
  useToggleComponent(wrapper, hide);
  return (
    <>
      <Tooltip title={t("parameter.wifiProfiles.edit_profile")}>
        <EditLogo className="w-6 h-6" onClick={toggle} />
      </Tooltip>
      {show && (
        <EditProfileModal
          onSubmit={onSubmit}
          onCancel={hide}
          wrapper={wrapper}
          profile={profile}
          simple={simple}
        />
      )}
    </>
  );
}
export default EditProfile;

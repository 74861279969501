import React, { useContext } from "react";
import Option from "./parameters/Option";
import Textbox from "./parameters/Textbox";
import Selector from "../layout/Selector";
import PasswordBox from "./parameters/PasswordBox";
import FileUploader from "./parameters/FileUploader";
import FileUploaderDropzone from "./parameters/FileUploaderDropzone";
import WifiProfile from "./parameters/WifiProfileWindows";
import MultipleOptionMenu from "./parameters/MultipleOptionMenu";
import { PolicyContext } from "./policyContext";
function Parameter({ definition, profileId }) {
  const {editMode} = useContext(PolicyContext)
  if (definition) {
    if (definition.hidden) {
      return <></>;
    }
    switch (definition.type) {
      case "menu":
      case "xmlMenu":
      case "solvedMenu":
      case "linkedSolvedMenu":
      case "queryMenu":
        return (
          <>
            <Option definition={definition} />
          </>
        );

      case "text":
      case "xmlText":
        return <Textbox definition={definition} profileId={profileId} />;
      case "queryText":
        return (
          <Textbox
            definition={definition}
            profileId={profileId}
            prefilled={definition.prefilled}
          />
        );
      case "password":
      case "xmlPassword":
        return <PasswordBox definition={definition} profileId={profileId} />;
      case "localAdmin":
        return (
          <Selector
            id={definition.id}
            defaultSelectedUserIds={[...definition.selected.users]}
            defaultSelectedGroupIds={[...definition.selected.groups]}
            profileId={profileId}
          />
        );
      case "file":
        return (
          // <FileUploader
          //   id={definition.id}
          //   file_type={definition.file_type}
          //   file_name={definition.selected && definition.selected}
          //   profileId={profileId}
          //   extension={definition.extension}
          //   maxSize={definition.max_size}
          // />
          <FileUploaderDropzone
            id={definition.id}
            selected={definition?.selected?.name}
            extension={definition?.acceptedExtensions}
            profileId={profileId}
          />
        );
      case "wifiProfiles":
        return <WifiProfile profileId={profileId} definition={definition} />;
      case "multipleMenu":
        return <MultipleOptionMenu profileId={profileId} definition={definition} disabled={!editMode} />
      default:
        return <p></p>;
    }
  }
}

export default Parameter;

import React, { useState } from "react";
import Field from "./Field";

function TextboxField({ value, setValue, isValid, validator, ...attributes }) {
  const [innerValue, setInnerValue] = useState(value);
  const [valid, setValid] = useState(true);
  const handleType = (e) => {
    const text = e.target.value;
    if (e.target.value.match(validator) != null) {
      setValid(true);
      isValid && isValid(true);
    } else {
      setValid(false);
      isValid && isValid(false);
    }
    setInnerValue(text);
    setValue && setValue(text);
  };
  return (
    <Field
      value={innerValue}
      onChange={handleType}
      invalid={!valid}
      {...attributes}
    />
  );
}

export default TextboxField;

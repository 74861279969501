import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Chevron } from "../../icons/arrow.svg";
import { useTranslation } from "react-i18next";
import { useToggleComponent } from "../../../tools/hooks";

function ComboboxOptions({ options,selected, title, onChange,disabled }) {
  const { t } = useTranslation("global");
  const [selectedList, setSelectedList] = useState(selected || []);
  const [isExpanded, setIsExpanded] = useState(false);
  const wrapper = useRef(null);
  const hide = () => setIsExpanded(false);
  const toggle = () => !disabled && setIsExpanded(!isExpanded);
  useToggleComponent(wrapper, hide);
  const handleOnClick = (value, checked) =>
    setSelectedList((p) => {
      if (checked) {
        return p.includes(value) ? p : [...p, value];
      } else {
        return p.filter((i) => i !== value);
      }
    });
  const isSelected = (value) => selectedList.includes(value);
  useEffect(() => onChange && onChange(selectedList), [onChange, selectedList]);
  return (
    <>
      <div className="w-full relative z-10 mx-3 text-sm ">
        <div
          className={`w-full border-fgray-400 bg-white z-20 ${
            isExpanded
              ? "rounded-t-lg border-t border-l border-r"
              : "rounded-lg border"
          } `}
          onClick={toggle}
        >
          <div className="pl-3 pr-2 py-2 my-auto flex justify-between">
            <div className=" text-black ">
              {selectedList.length === 0
                ? t("words.select_value")
                : t("words.n_selected", { count: selectedList.length })}
            </div>
            <div className="rotate-180">
              <Chevron className="h-4 w-4" />
            </div>
          </div>
        </div>
        { isExpanded && (
          <div
            className="border-b border-r border-l rounded-b-lg w-full bg-white z-20 absolute border-fgray-400"
            ref={wrapper}
          >
            {options.map((option) => (
              <ComboboxOptionItem
                value={option?.value}
                onClick={handleOnClick}
                defaultChecked={isSelected(option?.value)}
              >
                {t(option?.label, option?.label)}
              </ComboboxOptionItem>
            ))}
          </div>
        )}
      </div>
    </>
  );
}

function ComboboxOptionItem({ onClick, defaultChecked, children, value }) {
  const [isChecked, setIsChecked] = useState(defaultChecked || false);
  const handleOnClick = () => {
    onClick && onClick(value, !isChecked);
    setIsChecked(!isChecked);
  };
  function Checkbox({ ...rest }) {
    return (
      <>
        <input
          type="checkbox"
          {...rest}
          className="rounded focus:outline-none"
        />
      </>
    );
  }
  return (
    <>
      <div className="flex px-5 py-2.5 space-x-2" onClick={handleOnClick}>
        <Checkbox defaultChecked={isChecked} />
        <div className="flex hover:font-medium font-normal space-x-2 cursor-pointer ">
          {children}
        </div>
      </div>
    </>
  );
}
export default ComboboxOptions;

import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { ReactComponent as DropLogo } from "../../icons/install.svg";
import { ReactComponent as DownloadLogo } from "../../icons/download.svg";
import { ReactComponent as InvalidLogo } from "../../icons/circleX.svg";
import { ReactComponent as ReplaceLogo } from "../../icons/refresh.svg";
import { PolicyContext } from "../policyContext";

function FileUploaderDropzone({ id, selected, extension, profileId }) {
  const { t } = useTranslation("global");
  const [file, setFile] = useState({
    code: selected ? 200 : 0,
    name: selected,
  });
  const acceptedExtensions =
    extension &&
    extension.reduce((acc, ext) => {
      acc[ext] = [];
      return acc;
    }, {});
  const setFileName = (newName) => setFile((p) => ({ name: newName, ...p }));
  const inputRef = useRef(null);
  const { editMode } = useContext(PolicyContext) || true;
  useEffect(() => {
    setFileName(selected);
    inputRef.current.dataset.fileName = selected;
  }, [selected]);
  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      if (acceptedFiles.length) {
        const acceptedFile = acceptedFiles[0];
        // inputRef.current.files.push(acceptedFile);
        inputRef.current.dataset.fileCode = 201;
        window[id] = acceptedFile;
        setFile({ code: 200, name: acceptedFile.name });
      }
      if (fileRejections.length) {
        setFile({ code: 400, name: fileRejections[0].file.name });
        inputRef.current.dataset.fileCode = 400;
        inputRef.current.dataset.fileInfo = JSON.stringify({
          name: fileRejections[0].file.name,
          code: 400,
        });
      }
    },
    [id]
  );
  useEffect(() => {
    if ([200, 201].includes(file.code)) {
      const input = document.getElementById(profileId);
      Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        "checked"
      ).set.call(input, true);
      input.dispatchEvent(new Event("click", { bubbles: true }));
    } else{
      const input = document.getElementById(profileId);
        Object.getOwnPropertyDescriptor(
          window.HTMLInputElement.prototype,
          "checked"
        ).set.call(input, false);
        input.dispatchEvent(new Event("click", { bubbles: true }));
    }
  }, [file, profileId]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: acceptedExtensions,
    disabled: !editMode,
  });
  return (
    <>
      <div {...getRootProps({ id: `dropzone_${id}` })}>
        <input {...getInputProps({ id: id })} ref={inputRef} />
        <div
          className={`p-2 mx-4 mt-2 rounded-xl shadow-md flex justify-between w-full ${
            file.code == 400
              ? "bg-red-200 text-red-800"
              : "text-fgray-400 bg-fwhite"
          } ${editMode ? "cursor-pointer" : "cursor-not-allowed"} `}
        >
          {isDragActive ? (
            <>
              <div className="flex">
                <div className="w-8 h-8 items-center align-middle flex">
                  <DropLogo className="w-5 h-5 text-forange-400 m-auto" />
                </div>
                <div className="px-4 text-fgray-400 m-auto">
                  {t("pages.adddevice.dropzone.drag")}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex w-full">
                <div className="w-8 h-8 items-center align-middle flex">
                  {file.code == 400 ? (
                    <InvalidLogo className="w-6 h-6 text-red-800 m-auto" />
                  ) : [200, 201].includes(file.code) ? (
                    <ReplaceLogo className="w-6 h-6 text-fblack m-auto rotate-90" />
                  ) : (
                    <DownloadLogo className="w-5 h-5 text-forange-400 m-auto rotate-180" />
                  )}
                </div>
                <div
                  className={`px-2 my-auto truncate overflow-hidden whitespace-nowrap  `}
                >
                  {[200, 201].includes(file.code) ? (
                    t("pages.policy.parameters.dropzone.replace_file", {
                      fileName: file.name,
                    })
                  ) : file.code == 0 ? (
                    t("pages.policy.parameters.dropzone.upload")
                  ) : file.code == 400 ? (
                    t("pages.policy.parameters.dropzone.not_accepted", {
                      fileName: file.name,
                    })
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default FileUploaderDropzone;

import React, { useState, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { postApi } from "../../tools/axiosInstances";
import MultilineTranslation from "../layout/MultilineTranslation";
import StandardButton from "../layout/buttons/StandardButton";
import { useToggleComponent } from "../../tools/hooks";
import ModalCard, {
  ModalCardTitle,
  ModalCardBody,
  ModalCardButtons,
} from "../layout/ModalCard";
import ActionButton from "../layout/buttons/ActionButton";
import { PortalContext } from "../../PortalContext";
import { MoreButtonsChild } from "../layout/buttons/MoreButtons";
import { ReactComponent as PasscodeLogo } from "../icons/lock-open.svg";
import { ReactComponent as SyncLogo } from "../icons/sync.svg";
import { ReactComponent as RebootLogo } from "../icons/reboot.svg";
import { ReactComponent as ResetLogo } from "../icons/reset.svg";
import { ReactComponent as RemoveLogo } from "../icons/remove.svg";
function DeviceActionModal({
  selected,
  action,
  clear,
  setDeviceInfo,
  more,
  unenrolled,
  onSubmitAdditionalTasks,
}) {
  const { t } = useTranslation("global");
  const { deviceMode } = useContext(PortalContext);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const wrapper = useRef(null);
  const toggle = () => {
    selected?.length > 0 && setShow(!show);
  };
  const hide = () => setShow(false);
  useToggleComponent(wrapper, hide);
  const getLogo = () => {
    switch (action) {
      case "sync":
        return SyncLogo;
      case "passcode":
        return PasscodeLogo;
      case "reboot":
        return RebootLogo;
      case "reset":
        return ResetLogo;
      case "remove":
        return RemoveLogo;
      default:
        return null;
    }
  };
  const Logo = getLogo();
  function handleActionClick() {
    setLoading(true);
    let reqBody = "";
    switch (action) {
      case "sync":
        reqBody = {
          kind: "syc",
          target: "dev",
          detail: [],
        };
        break;
      case "reboot":
        reqBody = {
          kind: "rbt",
          target: "dev",
          detail: [],
        };
        onSubmitAdditionalTasks &&
          onSubmitAdditionalTasks([
            { actionName: "rebootNow", actionState: "pending" },
          ]);
        break;
      case "reset":
        reqBody = {
          kind: "rst",
          target: "dev",
          detail: [],
        };
        onSubmitAdditionalTasks &&
          onSubmitAdditionalTasks([
            {
              actionName: "factoryResetKeepEnrollmentData",
              actionState: "pending",
            },
          ]);
        break;
      case "remove":
        reqBody = {
          kind: "del",
          target: "dev",
          detail: [],
        };
        break;
      case "passcode":
        reqBody = {
          kind: "ulk",
          target: "dev",
          detail: [],
        };
        onSubmitAdditionalTasks &&
        onSubmitAdditionalTasks([
          {
            actionName: "resetPasscode",
            actionState: "pending",
          },
        ]);
        break;
      default:
        break;
    }
    selected.map((device) => {
      reqBody.detail.push({
        deviceId: device?.original?.id || device.id,
        targetType: device?.original?.targetType || device.targetType,
      });
    });

    postApi("/tasks/", reqBody).then((response) => {
      toggle();
      setLoading(false);
      clear && clear();
    });
  }

  return (
    <>
      {more ? (
        <MoreButtonsChild
          id={action}
          onClick={toggle}
          tooltip={`tooltip.device.${
            unenrolled ? "unenrolled" : deviceMode
          }.${action}`}
          Icon={Logo}
        >
          {t(`pages.device.${action}`)}
        </MoreButtonsChild>
      ) : (
        <ActionButton
          id={action}
          Icon={Logo}
          onClick={toggle}
          tooltip={`tooltip.device.${
            unenrolled ? "unenrolled" : deviceMode
          }.${action}`}
          subtle
        >
          {t(`pages.device.${action}`)}
        </ActionButton>
      )}
      {show && (
        <ModalCard wrapper={wrapper}>
          <ModalCardTitle>
            {t(`pages.device.modal.${action}.title`, {
              deviceName: selected.map(
                (row) => row?.original?.deviceName || row?.deviceName
              ),
              count: selected.length,
            })}
          </ModalCardTitle>
          <ModalCardBody>
            {
              <MultilineTranslation
                text={t(`pages.device.modal.${action}.body`, {
                  deviceName: selected.map(
                    (row) => row?.original?.deviceName || row?.deviceName
                  ),
                  count: selected.length,
                })}
              />
            }
          </ModalCardBody>
          <ModalCardButtons>
            <StandardButton onClick={toggle} subtle>
              {t(`pages.device.modal.cancel`)}
            </StandardButton>
            <StandardButton onClick={handleActionClick} loading={loading}>
              {t(`pages.device.modal.${action}.confirm`)}
            </StandardButton>
          </ModalCardButtons>
        </ModalCard>
      )}
    </>
  );
}

export default DeviceActionModal;

import React, { useContext, useEffect, useState } from "react";
import { PortalContext } from "../../PortalContext";
import Objekt from "./Objekt";
import CollapsibleSummary from "../layout/CollapsibleSummary";
import Profile from "./Profile";
import { PolicyContext } from "./policyContext";
import { postApi } from "../../tools/axiosInstances";
import { useTranslation } from "react-i18next";
import LoadingTable from "../layout/loading/LoadingTable";
import WifiProfileWindows from "./parameters/WifiProfileWindows";
import WifiProfileApple from "./parameters/WifiProfileApple";
function ObjectList({}) {
  const { deviceMode } = useContext(PortalContext);
  const { windowsObjects, ipadObjects, macosObjects } =
    useContext(PolicyContext);
  const objectEntries = () => {
    switch (deviceMode) {
      case "windows":
        return Object.entries(windowsObjects);
      case "ipad":
        return Object.entries(ipadObjects);
      case "macos":
        return Object.entries(macosObjects);
      default:
        return [];
    }
  };
  return (
    <>
      {objectEntries().length > 0 ? (
        objectEntries().map(([objectId, profiles]) => (
          <PolicyObject
            key={objectId}
            objectId={objectId}
            objectProfiles={profiles}
          />
        ))
      ) : (
        <LoadingTable />
      )}
    </>
  );
}

function PolicyObject({ objectId, objectProfiles }) {
  const [revertable, setRevertable] = useState(false);
  const { deviceMode } = useContext(PortalContext);
  const {
    filter,
    refresh,
    windowsProfilesStatus,
    ipadProfilesStatus,
    macosProfilesStatus,
  } = useContext(PolicyContext);
  const { t } = useTranslation("global");
  useEffect(() => {
    for (let i = 0; i < objectProfiles.length; i++) {
      const profile = objectProfiles[i];
      const profileStatus = [
        ...windowsProfilesStatus,
        ...ipadProfilesStatus,
        ...macosProfilesStatus,
      ].find((item) => item.id === profile.id);
      if (wifiProfiles(profileStatus?.parameters||[])) {
        setRevertable(false);
      } else {
        profileStatus && setRevertable((p) => p || profileStatus?.revertable);
      }
    }
  }, [
    ipadProfilesStatus,
    macosProfilesStatus,
    objectProfiles,
    windowsProfilesStatus,
  ]);
  useEffect(() => setRevertable(false), [filter, refresh]);
  const submitRestore = () => {
    let req_body = {
      kind: "unm",
      target: "pol",
      detail: {
        object: objectId,
        scope: filter,
        target: deviceMode,
      },
    };
    postApi("/tasks/", req_body).then((response) => {});
  };
  const wifiProfiles = (parameters) => {
    for (let j = 0; j < parameters.length; j++) {
      const parameter = parameters[j];
      if (parameter.type === "wifiProfiles" || parameter.type === "ssid") {
        return true;
      }
    }
    return false;
  };
  return (
    <CollapsibleSummary
      reversable={revertable}
      title={t(`policy.${objectId}.displayName`)}
      triggerRestore={submitRestore}
    >
      {objectProfiles &&
        objectProfiles.map((profile) =>
          wifiProfiles(profile.parameters) ? (
            deviceMode === "windows" ? (
              <WifiProfileWindows profileId={profile.id} />
            ) : (
              <WifiProfileApple profileId={profile.id} />
            )
          ) : (
            <Profile key={profile.id} profile={profile} />
          )
        )}
    </CollapsibleSummary>
  );
}

export default ObjectList;
